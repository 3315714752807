import { createRouter, createWebHistory } from 'vue-router';
// import { useI18n } from 'vue-i18n';
// const {t} =  useI18n()
import { i18n } from '../locales';
const routes = [
  
  {
    path: '/',
    meta: { 
      title:'Welcome'
    },
    component: () => import('@/views/comeOn/come'),
  },
  {
    path: '/comeTwo',
    name: 'comeTwo',
    meta: { 
      title:'Welcome'
    },
    component: () => import('@/views/comeOn/comeTwo'),
  },
  {
    path: '/index',
    name: 'index',
    meta: { 
      title:'home'
    },
    component: () => import('@/views/index'),
  },
  {
    path: '/login',
    name: 'login',
    meta: { 
      title:'login'
    },
    component: () => import('@/views/comeOn/login'),
  },
  {
    path: '/register',
    name: 'register',
    meta: { 
      title:'register'
    },
    component: () => import('@/views/comeOn/register'),
  },
  {
    path: '/customer',
    name: 'customer',
    meta: { 
      title:'customer_service'
    },
    component: () => import('@/views/customer'),
  },
  {
    path: '/chat',
    name: 'chat',
    meta: { 
      title:'chat'
    },
    component: () => import('@/views/chat'),
  },

  {
    path: '/p_index',
    name: 'p_index',
    meta: { 
      title:'products'
    },
    component: () => import('@/views/products/p_index'),
  },
  {
    path: '/detail',
    name: 'detail',
    meta: { 
      title:'product_details'
    },
    component: () => import('@/views/products/detail'),
  },
  {
    path: '/buy_success',
    name: 'buy_success',
    meta: { 
      title:'Purchase_completed'
    },
    component: () => import('@/views/products/buy_success'),
  },
  {
    path: '/list',
    name: 'list',
    meta: { 
      title:'My_investment'
    },
    component: () => import('@/views/my_investment/list'),
  },
  {
    path: '/invest_details',
    name: 'invest_details',
    meta: { 
      title:'invest_details'
    },
    component: () => import('@/views/my_investment/invest_details'),
  },
  {
    path: '/team_index',
    name: 'team_index',
    meta: { 
      title:'team'
    },
    component: () => import('@/views/team/team_index'),
  },
  {
    path: '/team_apply',
    name: 'team_apply',
    meta: { 
      title:'team_apply'
    },
    component: () => import('@/views/team/team_apply'),
  },
  {
    path: '/team_rule',
    name: 'team_rule',
    meta: { 
      title:'team_rule'
    },
    component: () => import('@/views/team/team_rule'),
  },
  {
    path: '/team_level',
    name: 'team_level',
    meta: { 
      title:'team_level'
    },
    component: () => import('@/views/team/team_level'),
  },
  
  {
    path: '/account_index',
    name: 'account_index',
    meta: { 
      title:'account'
    },
    component: () => import('@/views/account/account_index'),
  },

  {
    path: '/edit_data',
    name: 'edit_data',
    meta: { 
      title:'edit_data'
    },
    component: () => import('@/views/account/edit_data'),
  },
  {
    path: '/edit_pass',
    name: 'edit_pass',
    meta: { 
      title:'Change_Password'
    },
    component: () => import('@/views/account/edit_pass'),
  },
  {
    path: '/auth',
    name: 'auth',
    meta: { 
      title:'Real_name_authentication'
    },
    component: () => import('@/views/account/auth'),
  },

  {
    path: '/serve_agreen',
    name: 'serve_agreen',
    meta: { 
      title:'Term_of_service'
    },
    component: () => import('@/views/account/serve_agreen'),
  },

  {
    path: '/account_level',
    name: 'account_level',
    meta: { 
      title:'level_rule'
    },
    component: () => import('@/views/account/account_level'),
  },

  {
    path: '/banks',
    name: 'banks',
    meta: { 
      title:'Bank_Card'
    },
    component: () => import('@/views/account/banks'),
  },
  {
    path: '/add_bank',
    name: 'add_bank',
    meta: { 
      title:'Add_bank_card'
    },
    component: () => import('@/views/account/add_bank'),
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: { 
      title:'Recharge'
    },
    component: () => import('@/views/account/recharge'),
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    meta: { 
      title:'Withdrawal'
    },
    component: () => import('@/views/account/withdrawal'),
  },
  {
    path: '/historical_billing',
    name: 'historical_billing',
    meta: { 
      title:'Historical_billing'
    },
    component: () => import('@/views/account/historical_billing'),
  },
  {
    path: '/order_details',
    name: 'order_details',
    meta: { 
      title:'order_details'
    },
    component: () => import('@/views/account/order_details'),
  },
  {
    path: '/red_packets_index',
    name: 'red_packets_index',
    meta: { 
      title:'Receive_the_red_envelope'
    },
    component: () => import('@/views/redPackets/red_packets_index'),
  },

  {
    path: '/red_packets_item',
    name: 'red_packets_item',
    meta: { 
      title:'Receive_the_red_envelope'
    },
    component: () => import('@/views/redPackets/red_packets_item'),
  },
  {
    path: '/forget_password',
    name: 'forget_password',
    meta: { 
      title:'forget_password'
    },
    component: () => import('@/views/comeOn/forget_password'),
  },
  {
    path: '/next',
    name: 'next',
    meta: { 
      title:'Recharge'
    },
    component: () => import('@/views/account/next'),
  },
  
  
];


const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to,from,savedPosition){
    if(savedPosition) return savedPosition
    else return {top:0}
  }
});

router.beforeEach((to, from, next) => {
  const title = to.meta.title ? i18n.global.t(to.meta.title) : 'Default Title';
  document.title = title; // 更新浏览器标签页标题
  next();
})

export default router;