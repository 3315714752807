/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-11-15 16:45:35
 * @LastEditors: CZK
 * @LastEditTime: 2024-11-22 09:35:02
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { i18n } from './locales';
import 'vant/lib/index.css';
import vconsole from 'vconsole'
import './assets/css/iconfont.css'
import VueQrcode from 'vue-qrcode'
import { Switch, Swipe , SwipeItem,Notify,Popover,showToast,Uploader,DatePicker,Popup,ActionSheet,Field, CellGroup ,List } from 'vant';
// px2rem 自适应
import 'lib-flexible'
// const vConsole = new vconsole()
const app = createApp(App);
app.use(router);
app.use(i18n)
app.use(Switch)
app.use(Swipe)
app.use(SwipeItem)
app.use(Notify)
app.use(Popover)
app.use(Uploader)
app.use(DatePicker)
app.use(Popup)
app.use(ActionSheet)
app.use(Field);
app.use(CellGroup);
app.component('vue-qrcode', VueQrcode)
app.use(List);
app.config.globalProperties.$toast = showToast
app.mount('#app')
