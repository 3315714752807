import { createI18n,useI18n } from "vue-i18n";
import zhCN from './json/zh.json'
import enUS from './json/en.json'
import Brazil from './json/Brazil.json'
import local from '@/utils/localstorage'
const i18n = createI18n({
    legacy:false,
    locale:local.getItem('lang')||'Brazil',
    globalInjection:true,
    messages:{
        zhCN:zhCN,
        enUS:enUS,
        Brazil:Brazil
    }
})

const locale = i18n.global.locale
export {locale, useI18n, i18n}