/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-11-19 09:34:41
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-04 18:32:54
 */
const CANCHE_KEY = 'FUND_'
class localCache{
    setItem(key,val){
        window.localStorage.setItem(`${CANCHE_KEY}${key}`,JSON.stringify(val))
    }
    getItem(key){
        const value = window.localStorage.getItem(`${CANCHE_KEY}${key}`)
        if(value&&value!=null&&value!='null'&&value!='undefined'&&value!=undefined) return JSON.parse(value)
    }
    deleteItem(key){
        window.localStorage.removeItem(`${CANCHE_KEY}${key}`)
    }
    clear(){
        window.localStorage.clear()
    }
}
export default new localCache()