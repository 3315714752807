<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-11-15 16:45:35
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-06 10:51:34
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
    
  },
  data(){
    return {
      
    }
  }
}
</script>

<style lang="less">
#app{
  max-width:100vw;
  min-height:100vh;
}
body,html,input{
  all:unset;
}
.one-line{
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}
.two-line{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  margin: 0; 
}
input[type=number] {
  -moz-appearance:textfield;
}
</style>
